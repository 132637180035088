export enum FederalTaxClassification {

    SOLE = 'SOLE',
    C_CORP = 'C_CORP',
    S_CORP = 'S_CORP',
    PARTNERSHIP = 'PARTNERSHIP',
    TRUST_ESTATE = 'TRUST_ESTATE',
    LLC = 'LLC',
    OTHER = 'OTHER'

}
